.buttonRow {
    display        : flex;
    align-items    : center;
    margin-top     : 14px;
    margin-bottom  : 20px;
    flex-wrap      : wrap;
    justify-content: space-around;
    & > a {
        &:first-child {
            margin-right: 20px;
        }
        margin-bottom: 20px;
    }
}

.time {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    height          : 83px;
    max-width       : 530px;
    background-color: #f9f9f9;
    color           : #464646;
    padding         : 20px 25px;
    border-radius   : 10px;
    cursor          : default !important;
    text-align      : center;
    line-height     : 180%;
}

.title {
    text-align: center;
    margin    : 100px auto 40px !important;
    font-size : 40px !important;
}

.subtitle {
    width     : 100%;
    text-align: center;
    max-width : inherit !important;
    font-size : 24px !important;
    color     : #787878 !important;
}

.text {
    text-align: center;
    margin    : 10px auto 10px !important;
    font-size : 20px !important;
}

.techicalSupportBlock {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    margin         : 0 auto;
}

.href {
    font-size      : 28px !important;
    line-height    : 130%;

    justify-content: center;
    display        : flex;
    align-items    : center;

    color          : #4f8ce8;
}

.freeCall {
    font-size  : 13px !important;
    line-height: 130%;

    display    : flex;
    align-items: center;
    text-align : center;

    color      : #808080;
}

.backButtons {
    display        : flex;
    justify-content: center;
    margin-top     : 20px;
    margin-bottom  : 50px;
}

.noUnderline {
    text-decoration: none;
}

@media (max-width: 767px) {
    .time {
        width: inherit;
    }

    .buttonRow {
        & > a {
            &:first-child {
                margin-right: 0;
            }
        }
    }
}
