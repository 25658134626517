@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/OpenSansRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Open Sans"), local("OpenSans"),
    url("./assets/fonts/OpenSansBold.ttf") format("truetype");
}

body {
  margin: 0;
}

body * {
  font-family: "Open Sans";
}

.link {
  border: none !important;
  color: #4f8ce8;
  text-decoration: none;
  display: inline-block;
  white-space: normal !important;
}

.link:hover {
  color: #6eb9f7;
}

:root{
  --grey  : #f5f5f5;
  --white : #ffffff;
  --yellow: #ffffcc;
  --red   : #ffcccc;
  --green : #ccffcc;
}