.container {
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .container {
    display: none;
  }
}

.text {
  color: #555 !important;
  font-weight: bold;
  max-width: 300px;
  font-size: 14px;
}

.text:hover {
  color: #111;
}
