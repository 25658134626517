.DraftEditor-editorContainer h1 {
  font-size: 32px;
}
.DraftEditor-editorContainer h2 {
  font-size: 24px;
}
.DraftEditor-editorContainer h3 {
  font-size: 16px;
}
.public-DraftEditor-content[contenteditable="true"] {
  border: 1px solid #e3e3e3 !important;
  background-color: #fff !important;
}

.public-DraftEditor-content[contenteditable="false"] {
  padding: 0;
}
.public-DraftEditorPlaceholder-root {
  /* padding: 0 !important; */
}
.RichTextEditor__root___2QXK- {
  background-color: transparent !important;
  border: none !important;
  min-height: 44px;
}
.EditorToolbar__root___3_Aqz {
  border: none !important;
}

.EditorToolbar__root___3_Aqz {
  margin-left: 0;
}
